@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100-900;1,100-900&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
